var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("View all reports")]),
      _c("ck-resource-listing-table", {
        ref: "reportsTable",
        attrs: {
          uri: "/reports",
          columns: [
            {
              heading: "Type",
              render: function(report) {
                return report.report_type
              }
            },
            {
              heading: "From",
              render: function(report) {
                return report.starts_at
                  ? _vm.formatDate(report.starts_at)
                  : "N/A"
              }
            },
            {
              heading: "To",
              render: function(report) {
                return report.ends_at ? _vm.formatDate(report.ends_at) : "N/A"
              }
            },
            {
              heading: "Date / Time",
              render: function(report) {
                return _vm.formatDateTime(report.created_at)
              }
            }
          ],
          "action-text": "Download"
        },
        on: { action: _vm.onDownload }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }